.ChangeRoleModal {
  min-width: 400px;
  max-width: 640px;

  &.p-dialog:not([class*="p-dialog-"]) {
    display: block;
  }
  &.loading {
    .p-dialog-content {
      display: flex;
      justify-content: center;
    }
  }
  .p-radiobutton-label {
    cursor: pointer;
  }
  .p-radiobutton-label-description {
    font-size: 12px;
    color: #878787;
  }
}
